import React from "react";
import { Link } from "react-router-dom";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import { useLoadComments } from "../../services/ReactionService";
import FormatDate from "../App/FormatDate";
import CommentAdd from "./CommentAdd";

import styles from "./CommentsListing.module.css";

interface IProps {
  statement: IStatement;
}

const CommentsListing: React.FC<IProps> = ({ statement }) => {
  const { comments, refresh } = useLoadComments(statement.id);
  const { loggedIn } = useAuthUserContext();

  return (
    <div className={styles.CommentsListing}>
      {loggedIn && (
        <CommentAdd statement={statement} onCommentAdded={() => refresh()} />
      )}
      {!loggedIn && (
        <Link to={`/login?redirect=/statement/${statement.id}#comments=true`}>
          Login to comment
        </Link>
      )}
      {comments?.map((comment) => (
        <div className={styles.Comment} key={comment.id}>
          <p>{comment.comment}</p>
          <div className={styles.MetaData}>
            <FormatDate date={comment.timestamp} />{" "}
            {comment.user ? (
              <Link to={`/profile/${comment.user.uuid}`}>
                {comment.user.name}
              </Link>
            ) : (
              "unknown"
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentsListing;
